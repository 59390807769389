<template>
    <div>
      <main>
        <!-- 麵包屑 -->
        <section class="container" data-aos="fade-down"  data-aos-delay="500">
          <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb" class="badge">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="unintended-2">我意外懷孕了</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="unintended-3-1">我想要生下來</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="medical-3-2">醫療議題</router-link></li>
              <li class="breadcrumb-item active" aria-current="page"><router-link to="">產檢計畫</router-link></li>
            </ol>
          </nav>
        </section>

        <!-- 主要區塊 -->
        <section class="container step-rwd">
          <div class="step">
            <!-- 裝飾用圖片 -->
            <img class="step-circle" src="../../assets/image/front/image_circleword.svg">
            <img class="image1" src="../../assets/image/front/page02_image1.svg">
            <img class="image2" src="../../assets/image/front/page02_image2.svg">
            <img class="image3" src="../../assets/image/front/page02_image3.svg">
            <!-- 結束 -->
            <div class="title-box">
              <p class="step-text" data-aos="fade-up" >ANSEWR</p>
              <h2 class="step-title" data-aos="fade-up">選擇・答案</h2>
              <img class="title-line" src="../../assets/image/front/choose_title_line.svg" data-aos="fade-up" >
              <p class="step-sologan" data-aos="fade-up" >為自己所想，為自己所選。</p>
            </div>
            <div class="container step-box">
              <div class="step-box-inner">
                <div class="box-title" data-aos="fade-up">
                  <h2>｜產檢計畫｜</h2>
                </div>
                <div class="select-menu">
                  <img class="mb-60" data-aos="fade-up" src='../../assets/image/front/page02_pic3.jpg'>
                  <hr data-aos="fade-up">
                    <!-- 選項開始 -->
                    <!-- 選項開始 -->
                    <ul class="select-item container-inner mt-60">
                      <li data-aos="fade-up">
                        <p>產檢是孕婦生活中的一部分，透過產檢能了解腹中胎兒是否健康，但產檢自費項目眾多，自費產檢到底要做哪個？以下整理出懷孕產檢懶人包，小至產檢次數、產檢時間、產檢補助，大至產檢項目、產檢費用、產檢推薦，一次解惑！</p>
                        <img class="rounded-0 w-100 pt-2 pb-60" src="https://images.ctfassets.net/i2o3e6knau3c/3W5vqi69uXO5ahhhIEhijU/b949140117914dda12f5cd3a9ba33f01/0209-15.png?w=1026&h=876&q=100&fm=webp" alt="">
                      </li>
                      <li data-aos="fade-up">
                        <h3>一、懷孕多久開始產檢？產檢多久一次？</h3>
                        <p>懷孕產檢對於孕婦們可說是例行公事，懷孕初期至少一個月要見到醫師一次，確認寶寶成長，也進行檢查確認寶寶是否健康。我相信不少老手們對於產檢已經十分了解，但對於新手爸媽們來說，也許連懷孕多久開始產檢都不清楚，更別說產檢時間、產檢週數了！</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>（一）懷孕多久開始產檢？</h3>
                        <p>有些人認為懷孕週數一定要到領媽媽手冊才能產檢，其實是錯誤的觀念，雖然驗孕驗到懷孕，但並不代表是正常懷孕，仍有子宮外孕，或是懷孕初期出血、不穩定的可能，因此懷孕初期的檢查更是不可忽略！</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>（二）健保給付第一次產檢時間：</h3>
                        <p>第一次健保例行產檢時間約為懷孕9-12週，也就是看到胎兒心跳，且較穩定時，會發予產婦媽媽手冊，也會進行首次例行產檢基本檢查。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>（三）產檢假</h3>
                        <p>如產檢時間為上班時間，需要向公司請假者，一般需要出示產檢證明，如：產檢批價單、媽媽手冊紀錄，或產檢診斷證明書等等，詳細內容依照各公司規定有所不同，如需要協助可於產檢時告知。</p>
                      </li>
                     <li data-aos="fade-up">
                        <h3>二、健保產檢次數有幾次？產檢補助項目快速看</h3>
                        <p>懷孕檢查項目多到眼花撩亂嗎？門診常遇到產婦對於產檢項目有諸多疑惑，「自費產檢有必要嗎？」、「自費產檢到底要做哪個？」、「x染色體脆折症有需要做嗎？」、「唐氏症篩檢一定要做嗎？」
                          首先這裡大致上將產檢項目分為：「健保」與「自費」，做大方向的說明，這裡先針對健保給付產檢補助、項目說明。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>1.例行檢查</h3>
                        <h3>2.抽血</h3>
                        <h3>3.產檢驗尿</h3>
                        <h3>4.產檢超音波</h3>
                        <h3>5.產前乙型鏈球菌篩檢</h3>
                        <h3>6.妊娠糖尿病篩檢</h3>
                        <h3 class="pb-60">7.貧血檢查</h3>
                      </li>
                      <li data-aos="fade-up">
                        <h3>（二）健保產檢次數</h3>
                        <p>每個人在孕期中的狀況都不同，產檢次數、產檢頻率當然也不會一樣，但就一般產檢流程而言，由於健保有孕婦產檢補助，所以你的產檢時間表上至少會有14次的產檢時間。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>三、準爸媽必讀！完整擴大產檢時間表！</h3>
                        <p>懷孕初期多久產檢一次？懷孕中期多久產檢一次？懷孕後期多久產檢一次？以下列出一般產檢時間表供大家參考，雙胞胎產檢則依據產婦狀況而增加產檢次數。也在此再次強調，生男生女一樣好，產檢性別不是產檢最重要的關鍵。</p>
                        <img class="rounded-0 w-100 pt-2 pb-60" src="https://images.ctfassets.net/i2o3e6knau3c/hDNc9y12yKY2nfCtVU0go/f8bc4f8d359f4b916141ababba2208b5/haveababy_________________1.jpg" alt="">
                      </li>
                      <li data-aos="fade-up">
                      <p>參考來源：《Dr.Williams》
                      <a class="fs-5 text-danger" href="https://haveababy.tw/posts/pregnancy-22" target="_blank">最新產檢補助指南！產檢多久一次？自費項目那些必做？</a>
                      </p>
                      </li>
                    </ul>
                    <!-- 選項結束 -->
                    <hr data-aos="fade-up">
                    <div data-aos="fade-up">
                      <a class="goBack" onclick="history.back()" value="回到上一頁">回到上一頁</a>
                      <a class="goBack" href="#">返回首頁</a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      <Consult></Consult>
        <Circle></Circle>
      </main>
    </div>
</template>
<script>
import Consult from '../../components/Consult.vue'

export default {
  data () {
    return {

    }
  },
  mounted () {

  },
  components: {
    Consult
  }
}
</script>
